label.btn {
  padding-left: 30px;
}

.padded-horizontal {
  padding-bottom: 20px;
}

/*-------------- Dropzone -------------*/
.dropzone.dz-clickable {
  border: 2px dashed blue;
  margin: 10px 0;
}